import * as React from "react";
const SvgSoloseq = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 757.333 144"
    {...props}
  >
    <defs>
      <linearGradient
        id="soloseq_svg__a"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="scale(322.8661 -322.8661)rotate(-18.104 .046 -2.55)"
        gradientUnits="userSpaceOnUse"
        spreadMethod="pad"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#ff7d50",
          }}
        />
        <stop
          offset={0.523}
          style={{
            stopOpacity: 1,
            stopColor: "#645fe6",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#1ae299",
          }}
        />
      </linearGradient>
      <linearGradient
        id="soloseq_svg__b"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="scale(322.8661 -322.8661)rotate(-18.104 -.06 -2.58)"
        gradientUnits="userSpaceOnUse"
        spreadMethod="pad"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#ff7d50",
          }}
        />
        <stop
          offset={0.523}
          style={{
            stopOpacity: 1,
            stopColor: "#645fe6",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#1ae299",
          }}
        />
      </linearGradient>
      <linearGradient
        id="soloseq_svg__c"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="scale(322.8661 -322.8661)rotate(-18.104 .298 -2.637)"
        gradientUnits="userSpaceOnUse"
        spreadMethod="pad"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#ff7d50",
          }}
        />
        <stop
          offset={0.523}
          style={{
            stopOpacity: 1,
            stopColor: "#645fe6",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#1ae299",
          }}
        />
      </linearGradient>
      <linearGradient
        id="soloseq_svg__d"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="scale(322.8661 -322.8661)rotate(-18.104 .676 -2.697)"
        gradientUnits="userSpaceOnUse"
        spreadMethod="pad"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#ff7d50",
          }}
        />
        <stop
          offset={0.523}
          style={{
            stopOpacity: 1,
            stopColor: "#645fe6",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#1ae299",
          }}
        />
      </linearGradient>
      <linearGradient
        id="soloseq_svg__e"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="scale(322.8661 -322.8661)rotate(-18.104 .688 -2.747)"
        gradientUnits="userSpaceOnUse"
        spreadMethod="pad"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#ff7d50",
          }}
        />
        <stop
          offset={0.523}
          style={{
            stopOpacity: 1,
            stopColor: "#645fe6",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#1ae299",
          }}
        />
      </linearGradient>
      <clipPath id="soloseq_svg__f" clipPathUnits="userSpaceOnUse">
        <path d="M0 108h568V0H0Z" />
      </clipPath>
    </defs>
    <path
      d="M303.917 104.442c-5.21-2.28-9.28-5.606-12.209-9.977-2.929-4.373-4.395-9.582-4.395-15.628 0-5.023.838-9.187 2.512-12.487 1.674-3.304 3.882-6.049 6.627-8.233a40.967 40.967 0 0 1 9.001-5.443 115.429 115.429 0 0 1 9.837-3.836c3.3-1.117 6.325-2.281 9.07-3.49 2.742-1.209 4.953-2.65 6.627-4.324 1.676-1.675 2.512-3.862 2.512-6.559 0-2.978-1.28-5.302-3.837-6.976-2.56-1.675-6.164-2.512-10.813-2.512-5.119 0-9.606.998-13.467 3-3.861 1.998-7.373 4.814-10.535 8.442l-14.232-14.093c4.836-5.397 10.162-9.419 15.977-12.071 5.812-2.65 12.767-3.976 20.86-3.976 11.813 0 21.07 2.673 27.769 8.023 6.698 5.348 10.046 12.721 10.046 22.117 0 5.115-.839 9.419-2.513 12.906-1.674 3.489-3.884 6.371-6.627 8.651a40.143 40.143 0 0 1-9.001 5.652 103.87 103.87 0 0 1-9.837 3.907 160.73 160.73 0 0 0-9.07 3.348c-2.744 1.117-4.952 2.442-6.625 3.977-1.677 1.536-2.513 3.558-2.513 6.071 0 2.696 1.116 4.765 3.348 6.208 2.232 1.441 5.254 2.164 9.07 2.164 3.813 0 7.232-.747 10.257-2.233 3.02-1.49 6.021-3.768 8.998-6.838l14.232 14.094c-3.72 4.186-8.44 7.487-14.16 9.908-5.723 2.416-12.026 3.627-18.909 3.627-6.791 0-12.791-1.141-18-3.419"
      style={{
        fill: "url(#soloseq_svg__a)",
        stroke: "none",
      }}
      transform="matrix(1.33333 0 0 -1.33333 0 144)"
    />
    <path
      d="M374.591 87.488h70.048v18.838h-70.048z"
      style={{
        fill: "url(#soloseq_svg__b)",
        stroke: "none",
      }}
      transform="matrix(1.33333 0 0 -1.33333 0 144)"
    />
    <path
      d="M385.055 48.976h49.118v18.281h-49.118z"
      style={{
        fill: "url(#soloseq_svg__c)",
        stroke: "none",
      }}
      transform="matrix(1.33333 0 0 -1.33333 0 144)"
    />
    <path
      d="M374.591 7.814h70.743v18.977h-70.743z"
      style={{
        fill: "url(#soloseq_svg__d)",
        stroke: "none",
      }}
      transform="matrix(1.33333 0 0 -1.33333 0 144)"
    />
    <path
      d="M511.068 108c-7.349 0-14.163-1.28-20.44-3.837-6.28-2.559-11.77-6.14-16.467-10.744-4.699-4.604-8.35-9.976-10.952-16.117-2.605-6.138-3.908-12.837-3.908-20.093 0-7.165 1.327-13.837 3.977-20.024 2.652-6.185 6.325-11.604 11.024-16.255 4.696-4.653 10.207-8.281 16.535-10.883 6.325-2.603 13.16-3.907 20.512-3.907 7.345 0 14.138 1.304 20.371 3.907a52.847 52.847 0 0 1 8.834 4.7L555.302 0 568 12.698l-14.473 14.473a49.927 49.927 0 0 1 5.543 9.876c2.602 6.185 3.906 12.859 3.906 20.022 0 7.257-1.304 13.975-3.906 20.164-2.607 6.185-6.259 11.582-10.956 16.186-4.699 4.604-10.185 8.185-16.462 10.744-6.281 2.557-13.143 3.837-20.584 3.837ZM499.14 28.326c-3.584 1.486-6.677 3.604-9.279 6.349-2.607 2.742-4.606 6.021-6 9.836-1.396 3.814-2.095 8.046-2.095 12.698 0 6.232 1.233 11.651 3.698 16.256 2.464 4.605 5.883 8.185 10.257 10.744 4.37 2.557 9.488 3.837 15.347 3.837 4.466 0 8.488-.721 12.071-2.162 3.581-1.443 6.675-3.537 9.279-6.28 2.602-2.745 4.604-6 6-9.767 1.397-3.768 2.091-7.977 2.091-12.628 0-5.293-.92-10.048-2.752-14.269l-15.805 15.805-12.698-12.698 16.491-16.491c-4.199-2.299-9.086-3.463-14.677-3.463-4.373 0-8.351.744-11.928 2.233"
      style={{
        fill: "url(#soloseq_svg__e)",
        stroke: "none",
      }}
      transform="matrix(1.33333 0 0 -1.33333 0 144)"
    />
    <g
      clipPath="url(#soloseq_svg__f)"
      transform="matrix(1.33333 0 0 -1.33333 0 144)"
    >
      <path
        d="M0 0c3 0 5.6.6 7.8 2 2.2 1.3 4 3.1 5.2 5.5 1.2 2.4 1.8 5.1 1.8 8.1s-.6 5.7-1.9 8c-1.3 2.3-3 4.1-5.2 5.4-2.2 1.3-4.8 2-7.7 2-2.9 0-5.4-.7-7.7-2-2.2-1.3-4-3.1-5.2-5.4-1.3-2.3-1.9-5-1.9-8.1 0-3 .6-5.6 1.9-8 1.3-2.4 3-4.2 5.2-5.5C-5.4.7-2.9 0 0 0m0-18.7c-6.7 0-12.8 1.5-18.2 4.5-5.4 3-9.7 7.1-12.8 12.3-3.1 5.2-4.7 11-4.7 17.5S-34.1 27.8-31 33c3.1 5.1 7.4 9.2 12.8 12.2S-6.7 49.7.1 49.7c6.8 0 12.9-1.5 18.3-4.4 5.4-3 9.6-7 12.8-12.2 3.1-5.2 4.7-11 4.7-17.4 0-6.4-1.6-12.3-4.7-17.5-3.1-5.2-7.4-9.3-12.8-12.3-5.5-3.1-11.6-4.6-18.4-4.6"
        style={{
          fill: "#061c57",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(116.231 25.5)"
      />
      <path
        d="M161.531 106h20.6V8.3h-20.6z"
        style={{
          fill: "#061c57",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M0 0c3 0 5.6.6 7.8 2 2.2 1.3 4 3.1 5.2 5.5 1.2 2.4 1.8 5.1 1.8 8.1s-.6 5.7-1.9 8c-1.3 2.3-3 4.1-5.2 5.4-2.2 1.3-4.8 2-7.7 2-2.9 0-5.4-.7-7.7-2-2.2-1.3-4-3.1-5.2-5.4-1.3-2.3-1.9-5-1.9-8.1 0-3 .6-5.6 1.9-8 1.3-2.4 3-4.2 5.2-5.5C-5.5.7-2.9 0 0 0m0-18.7c-6.7 0-12.8 1.5-18.2 4.5-5.4 3-9.7 7.1-12.8 12.3-3.1 5.2-4.7 11-4.7 17.5S-34.1 27.8-31 33c3.1 5.1 7.4 9.2 12.8 12.2S-6.7 49.7.1 49.7c6.8 0 12.9-1.5 18.3-4.4 5.4-3 9.6-7 12.8-12.2 3.1-5.2 4.7-11 4.7-17.4 0-6.4-1.6-12.3-4.7-17.5-3.1-5.2-7.4-9.3-12.8-12.3-5.5-3.1-11.6-4.6-18.4-4.6"
        style={{
          fill: "#061c57",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(227.531 25.5)"
      />
      <path
        d="M0 0c-2.7 2.3-5.7 4.2-9 5.7s-6.5 2.8-9.8 3.9c-3.3 1.1-6.3 2.2-9.1 3.3-2.7 1.1-5 2.4-6.6 4-1.7 1.5-2.5 3.6-2.5 6.1 0 2.7 1.1 4.8 3.3 6.2 2.2 1.4 5.3 2.2 9.1 2.2s7.2-.7 10.3-2.2c3-1.5 6-3.8 9-6.8L8.9 36.5C5.2 40.7.5 44-5.3 46.4c-5.7 2.4-12 3.6-18.9 3.6-6.8 0-12.8-1.1-18-3.4-5.2-2.3-9.3-5.6-12.2-10-2.9-4.4-4.4-9.6-4.4-15.6 0-5 .8-9.2 2.5-12.5 1.7-3.3 3.9-6 6.6-8.2 2.7-2.2 5.7-4 9-5.4 3.3-1.4 6.5-2.7 9.8-3.8 3.3-1.1 6.3-2.3 9.1-3.5 2.7-1.2 5-2.7 6.6-4.3 1.7-1.7 2.5-3.9 2.5-6.6 0-3-1.3-5.3-3.8-7-2.6-1.7-6.2-2.5-10.8-2.5-5.1 0-9.6 1-13.5 3-3.9 2-7.4 4.8-10.5 8.4l-14.2-14.1c4.8-5.4 10.2-9.4 16-12.1 5.8-2.7 12.8-4 20.9-4 11.8 0 21.1 2.7 27.8 8 6.7 5.3 10 12.7 10 22.1 0 5.1-.8 9.4-2.5 12.9C4.9-5.2 2.7-2.3 0 0"
        style={{
          fill: "#061c57",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(65.5 58)"
      />
    </g>
  </svg>
);
export default SvgSoloseq;
